import React from "react";
import Layout2 from "../components/layout-2";
import { StaticImage } from "gatsby-plugin-image";
import { FaRegCheckCircle } from "react-icons/fa";
import TeamWork from "../images/undraw_Work_time_re_hdyv.svg";
import {
  Link as Anchor,
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Image,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  Textarea,
  UnorderedList,
} from "@chakra-ui/react";
import GrowthIcon from "../images/growth.svg";
import SupportIcon from "../images/support.svg";
import EthicsIcon from "../images/ethics.svg";
import DedicationIcon from "../images/dedication.svg";
import SEO from "../components/seo";

const AboutPage = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "kaminds",
    url: "https://www.kaminds.com/about",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917568015258",
      contactType: "technical support",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      "https://www.kaminds.com/about",
    ],
  };

  return (
    <Layout2>
      <SEO
        title="About"
        description="We provide ground-breaking solutions for food, nutraceutical &amp; pharmaceutical manufacturers so they can create sustainable and high quality products."
        keywords="About Kaminds, Kaminds, Vision, Mission"
        links={[{ rel: "canonical", href: "https://www.kaminds.com/about/" }]}
        schemaMarkup={schemaMarkup}
      />
      <Box
        marginTop={["80px", "80px", "80px", "100px", "100px"]}
        position="relative"
      >
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/about-us-small.jpg"
            // width={800}
            height={1100}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="about-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/about-us-medium.jpg"
            width={800}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="about-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/about-us.jpg"
            width={1920}
            height={900}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="about-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/about-us.jpg"
            width={1920}
            height={800}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="about-us"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
        >
          <StaticImage
            src="../images/about-us.jpg"
            width={1920}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="about-us"
            style={{ display: "block" }}
          />
        </Box>
        {/* <StaticImage
          src="../../images/human-nutrition.jpg"
          placeholder="tracedSVG"
          tracedSVGOptions={{ color: "#282765" }}
          width={1900}
          height={900}
          alt="human-nutrition"
          style={{ display: "block", opacity: "0.7" }}
        /> */}
        <Box
          margin="auto"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Box maxWidth="1300px" width="100%" margin="0 auto">
            <Flex margin="0 auto" flexDirection="column">
              <Box
                as="h1"
                fontSize={[
                  "70px",
                  "80px",
                  "85px",
                  "100px",
                  "115px",
                  "125px",
                  // "120px",
                ]}
                fontWeight="500"
                color="#fff"
                width="100%"
                fontFamily="Fjalla One !important"
                textAlign="center"
                lineHeight={["80px", "85px", "90px", "105px", "100px", "135px"]}
              >
                ABOUT US
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box padding="2em"></Box>
      <Box padding="2em">
        <Box marginTop="40px" maxWidth="1400px" margin="auto">
          <Flex wrap="wrap" justifyContent="space-between">
            <Box
              width={["100%", "100%", "100%", "48.5%", "48.5%"]}
              id="who-we-are"
              marginTop="35px"
              background="linen"
              boxShadow="3px 6px 8px rgba(0,0,0,.2)"
              padding="2em"
              borderRadius="30px"
            >
              {/* <Box
                as="h6"
                fontSize="18px"
                fontFamily="Roboto"
                fontWeight="500"
                color="#252869"
              >
                WHO WE ARE
              </Box> */}
              <Box
                as="h3"
                fontSize="50px"
                width={["100%", "100%", "80%", "80%", "80%"]}
                marginTop="20px"
                fontFamily="Roboto Condensed !important"
                color="#252869"
                wordBreak="keep-all"
              >
                Discover World-Class Ingredients That Can Transform Your
                Products
                <Box
                  as="p"
                  fontSize="18px"
                  fontFamily="Roboto"
                  marginTop="10px"
                  lineHeight="27px"
                  color="#252869"
                >
                  Our vision is to provide ground-breaking solutions for food,
                  nutraceutical &amp; pharmaceutical manufacturers so they can
                  create sustainable and high quality products.
                </Box>
              </Box>
            </Box>
            <Flex
              width={["100%", "100%", "100%", "48.5%", "48.5%"]}
              justifyContent="space-between"
              marginTop="35px"
            >
              <Box
                id="what-we-do"
                background="aliceblue"
                boxShadow="3px 6px 8px rgba(0,0,0,.2)"
                padding="2em"
                borderRadius="30px"
              >
                {/* <Box
                  as="h6"
                  fontSize="18px"
                  fontFamily="Roboto"
                  fontWeight="500"
                  color="#252869"
                >
                  WHAT WE DO
                </Box> */}
                <Box
                  as="h3"
                  fontSize="50px"
                  width={["100%", "100%", "80%", "80%", "80%"]}
                  marginTop="20px"
                  fontFamily="Roboto Condensed !important"
                  color="#252869"
                >
                  Delivering the World's Best Ingredient Solutions.
                  <Box
                    as="p"
                    fontSize="18px"
                    fontFamily="Roboto"
                    marginTop="12px"
                    lineHeight="27px"
                    color="#252869"
                  >
                    Our mission is to provide manufacturers across the world,
                    access to high-quality ingredients that prove to be
                    efficient and advantageous.
                    {/* <Box
                      as="p"
                      fontSize="22px"
                      fontFamily="Roboto Condensed !important"
                      marginTop="10px"
                      lineHeight="27px"
                      color="#252869"
                      fontWeight="500"
                      letterSpacing="0.57px"
                      fontWeight="600"
                    >
                      We are networkers connecting ingredients manufacturers and
                      users across all areas.
                    </Box>{" "}
                    <Box
                      as="p"
                      fontSize="18px"
                      fontFamily="Roboto"
                      marginTop="10px"
                      lineHeight="27px"
                      color="#252869"
                    >
                      We provide ground-breaking solutions for nutraceutical
                      &amp; pharmaceutical manufacturers so they can improve the
                      quality of products at the same time reduce costs.
                    </Box> */}
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Box>
      <Box
        padding={["2em", "4em", "4em", "4em", "4em"]}
        background="aliceblue"
        marginTop="200px"
      >
        <Box marginTop="40px" maxWidth="1700px" margin="auto">
          <Flex wrap="wrap">
            <Box
              width={["100%", "100%", "100%", "30%", "30%"]}
              marginTop="-200px"
              marginLeft={["0px", "0px", "0px", "100px", "100px"]}
              borderRadius="30px"
            >
              <StaticImage
                placeholder="blurred"
                src="../images/story.jpg"
                style={{
                  borderRadius: "30px",
                  overflow: "hidden",
                }}
              />
            </Box>
            <Box
              marginTop={["35px", "35px", "30px", "0px", "0px"]}
              width={["100%", "100%", "100%", "50%", "50%"]}
              paddingLeft={["0px", "0px", "0px", "150px", "150px"]}
            >
              {/* <Box
                as="h6"
                fontSize="40px"
                fontFamily="Roboto"
                fontWeight="600"
                color="#252869"
                fontFamily="Roboto Condensed !important"
              >
                A BIT OF STORY
              </Box> */}
              <Box
                as="h3"
                fontSize="20px"
                width="100%"
                marginTop="20px"
                // fontFamily="Roboto Condensed !important"
                color="#252869"
                lineHeight="33px"
                marginTop="50px"
              >
                Consumers are always looking for cheaper and better-quality
                alternatives, this pushes manufacturing companies to reduce
                production costs and increase the quality of their products. By
                utilizing the latest innovative ingredients and technology, we
                can better meet both challenges. At Kaminds, we strive to fulfil
                this need for the manufacturers, helping them achieve higher
                quality at a reduced cost. We are always on the search to find
                the best solutions for our customers by associating with top
                international manufacturers.
                {/* <Box
                  as="p"
                  fontSize="18px"
                  fontFamily="Roboto"
                  marginTop="12px"
                  lineHeight="27px"
                  color="#252869"
                >
                  For our customers, we provide access to the complete range of
                  ingredients that are healthier as well as sustainable.
                  <Box
                    as="p"
                    fontSize="22px"
                    fontFamily="Roboto Condensed !important"
                    marginTop="10px"
                    lineHeight="27px"
                    color="#252869"
                    fontWeight="500"
                    letterSpacing="0.57px"
                    fontWeight="600"
                  >
                    We are networkers connecting ingredients manufacturers and
                    users across all areas.
                  </Box>{" "}
                  <Box
                    as="p"
                    fontSize="18px"
                    fontFamily="Roboto"
                    marginTop="10px"
                    lineHeight="27px"
                    color="#252869"
                  >
                    We provide ground-breaking solutions for nutraceutical &amp;
                    pharmaceutical manufacturers so they can improve the quality
                    of products at the same time reduce costs.
                  </Box>
                </Box> */}
              </Box>
            </Box>
          </Flex>
        </Box>
      </Box>
      <Box padding="2em" marginTop="40px" background="#fff">
        <Box maxWidth="1500px" margin="auto">
          <Box
            color="#252869"
            fontSize="45px"
            fontWeight="400"
            textAlign="center"
            fontFamily="Roboto Condensed !important"
          >
            Our Values
          </Box>
          <Box
            height="4px"
            width="85px"
            background="#d61215"
            margin="5px auto 25px"
          ></Box>
          <Flex wrap="wrap">
            <Flex
              alignItems="center"
              direction="column"
              position="relative"
              width={[
                "100%",
                "calc(100% / 2 - 20px)",
                "calc(100% / 2 - 20px)",
                "calc(100% / 4 - 20px)",
              ]}
              margin="10px"
              marginLeft={["-10px", "10px", "10px", "10px"]}
              padding="1em"
            >
              <Box
                // position="absolute"
                // left="20px"
                // top="24px"
                padding="0.7em"
                borderRadius="50%"
                background="aliceblue"
                color="#252869"
                fontSize="40px"
              >
                <GrowthIcon style={{ color: "#252869", fontSize: "40px" }} />
              </Box>
              <Box
                as="h2"
                fontFamily="Roboto Condensed !important"
                fontSize="30px"
                fontWeight="500"
                color="#252869"
                marginTop="10px"
                // paddingLeft="2em"
              >
                Growth
              </Box>
              <Box
                as="p"
                // fontFamily="Barlow Condensed !important"
                fontSize="17px"
                fontWeight="500"
                color="#252869"
                textAlign="center"
                marginTop="8px"
                // paddingLeft="2em"
              >
                As you grow, so do we. We believe that our clients’ success is
                vital to our own success.
              </Box>
              {/* <Box as="p" color="#252869" margin="16px 0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Box> */}
            </Flex>
            <Flex
              alignItems="center"
              direction="column"
              position="relative"
              width={[
                "100%",
                "calc(100% / 2 - 20px)",
                "calc(100% / 2 - 20px)",
                "calc(100% / 4 - 20px)",
              ]}
              margin="10px"
              marginLeft={["-10px", "10px", "10px", "10px"]}
              padding="1em"
            >
              <Box
                // position="absolute"
                // left="20px"
                // top="24px"
                padding="0.7em"
                borderRadius="50%"
                background="linen"
                color="#252869"
                fontSize="40px"
              >
                <SupportIcon />
              </Box>
              <Box
                as="h2"
                fontFamily="Roboto Condensed !important"
                fontSize="30px"
                fontWeight="500"
                color="#252869"
                marginTop="10px"
                // paddingLeft="2em"
              >
                Customer Service
              </Box>
              <Box
                as="p"
                // fontFamily="Barlow Condensed !important"
                fontSize="17px"
                fontWeight="500"
                color="#252869"
                textAlign="center"
                marginTop="8px"
                // paddingLeft="2em"
              >
                Our goal is to provide customers with the best customer service
                possible. We easily go the extra mile for our clients.
              </Box>
              {/* <Box as="p" color="#252869" margin="16px 0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Box> */}
            </Flex>
            <Flex
              alignItems="center"
              direction="column"
              position="relative"
              width={[
                "100%",
                "calc(100% / 2 - 20px)",
                "calc(100% / 2 - 20px)",
                "calc(100% / 4 - 20px)",
              ]}
              margin="10px"
              marginLeft={["-10px", "10px", "10px", "10px"]}
              padding="1em"
            >
              <Box
                // position="absolute"
                // left="20px"
                // top="24px"
                padding="0.7em"
                borderRadius="50%"
                background="aliceblue"
                color="#252869"
                fontSize="40px"
              >
                <EthicsIcon />
              </Box>
              <Box
                as="h2"
                fontFamily="Roboto Condensed !important"
                fontSize="30px"
                fontWeight="500"
                color="#252869"
                marginTop="10px"
                // paddingLeft="2em"
              >
                Ethics
              </Box>
              <Box
                as="p"
                // fontFamily="Barlow Condensed !important"
                fontSize="17px"
                fontWeight="500"
                color="#252869"
                textAlign="center"
                marginTop="8px"
                // paddingLeft="2em"
              >
                We respect our customers and guarantee complete confidentiality.
              </Box>
              {/* <Box as="p" color="#252869" margin="16px 0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Box> */}
            </Flex>
            <Flex
              alignItems="center"
              direction="column"
              position="relative"
              width={[
                "100%",
                "calc(100% / 2 - 20px)",
                "calc(100% / 2 - 20px)",
                "calc(100% / 4 - 20px)",
              ]}
              margin="10px"
              marginLeft={["-10px", "10px", "10px", "10px"]}
              padding="1em"
            >
              <Box
                // position="absolute"
                // left="20px"
                // top="24px"
                padding="0.7em"
                borderRadius="50%"
                background="linen"
                color="#252869"
                fontSize="40px"
              >
                <DedicationIcon />
              </Box>
              <Box
                as="h2"
                fontFamily="Roboto Condensed !important"
                fontSize="30px"
                fontWeight="500"
                color="#252869"
                marginTop="10px"
                // paddingLeft="2em"
              >
                Dedication
              </Box>
              <Box
                as="p"
                // fontFamily="Barlow Condensed !important"
                fontSize="17px"
                fontWeight="500"
                color="#252869"
                textAlign="center"
                marginTop="8px"
                // paddingLeft="2em"
              >
                We are committed to providing superior quality ingredients that
                enhance your products.
              </Box>
              {/* <Box as="p" color="#252869" margin="16px 0">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </Box> */}
            </Flex>
          </Flex>
        </Box>
      </Box>
      {/* <Box
        maxWidth="1400"
        width="100%"
        margin="100px auto 0 auto"
        marginTop="125px"
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            display="flex"
            flexDirection="column"
            paddingTop="60px"
            width="50%"
            // marginRight="-50px"
            color="#2b2866"
          >
            <Box
              fontSize="70px"
              fontWeight="400"
              fontFamily="Roboto Condensed !important"
            >
              Our
              <Box
                as="span"
                display="block"
                lineHeight="2rem"
                fontFamily="Roboto Condensed !important"
              >
                Mission
              </Box>
              <Box
                as="span"
                display="block"
                fontFamily="Roboto Condensed !important"
              >
                &amp; Vision
              </Box>
            </Box>
            <Box paddingTop="8px">
              <Box as="p">
                Our mission is to give every pharma &amp; nutraceutical
                manufacturers in World, access to high-quality ingredients by
                providing a cost-efficient &amp; advantageous solution. To
                create and cater a range of chemicals and ingredients and to be
                a company that is creative, passionate, and reliable. To offer a
                complete range of ingredients that is healthier as well as
                sustainable. To provide ground-breaking solutions for
                nutraceutical &amp; pharmaceutical manufacturers so they can
                improve the quality of products at the same time reduce costs.
              </Box>
            </Box> */}
      {/* <Box paddingTop="50px">
              <Box as="p" fontWeight="500">
                <i>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iste
                  cumque, harum dignissimos iusto
                </i>
              </Box>
            </Box> */}
      {/* </Box>
          <Box
            marginLeft="100px"
            display="flex"
            flexDirection="column"
            width="50%"
            marginTop="80px"
            // alignItems="start"
          > */}
      {/* <TeamWork style={{ width: "80%", marginTop: "-130px" }} /> */}
      {/* <Box
              as="h2"
              fontSize="40px"
              fontWeight="500"
              fontFamily="Roboto Condensed !important"
              color="#252869"
            >
              Our Story
            </Box>
            <Box paddingTop="26px">
              <Box as="p" color="#252869">
                Back in 1998 when the Food and Nutraceutical Industry wasn’t
                main stream, Mr. Inder Karamchandani had the vision to be the
                key supplier of specialty ingredients for the Food and
                Nutraceutical Industry and founded G.R. &amp; Sons. Today G.R.
                &amp; Sons is an exclusive distributor to Edible Oil
                Manufacturers in Rajasthan, India. With over three decades of
                experience in specialty food and nutraceutical Consumers are
                always looking for cheaper and better-quality alternatives. This
                pushes manufacturing companies to reduce production costs and
                increase the quality of their products. By utilizing the latest
                innovative ingredients and technology, we can better meet both
                challenges. At Kaminds, we strive to fulfil this need for the
                manufacturers, helping them achieve higher quality at a reduced
                cost. We are always on the search to find the best solutions for
                our customers by associating with top international
                manufacturers.
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          as="flex"
          flexDirection="row"
          padding="2em 2em 3em"
          marginTop="100px"
        >
          <Box maxWidth="1200" margin="auto" marginTop="30px">
            <Box
              color="#252869"
              fontSize="50px"
              fontWeight="400"
              textAlign="center"
              fontFamily="Roboto Condensed !important"
            >
              Our Values
            </Box>
            <Box>
              <Box marginTop="30px">
                <Box as="p" fontSize="21px">
                  <Box as="b" fontSize="22px" color="#252869">
                    Time
                  </Box>{" "}
                  - We understand that your time is precious. In fact, our
                  strategic distribution network is built to reduce the delivery
                  time of your orders.
                </Box>
                <Box as="p" fontSize="21px" marginTop="20px">
                  <Box as="b" fontSize="22px" color="#252869">
                    Customer Service
                  </Box>{" "}
                  - Our goal is to provide customers with the best customer
                  service possible. We easily go the extra mile for our clients.
                </Box>
                <Box as="p" fontSize="21px" marginTop="20px">
                  <Box as="b" fontSize="22px" color="#252869">
                    Growth
                  </Box>{" "}
                  - With a team of high-calibre professionals, we’re able to
                  efficiently support your business needs. Because as partners
                  we believe your growth is vital for our growth.
                </Box>
                <Box as="p" fontSize="21px" marginTop="20px">
                  <Box as="b" fontSize="22px" color="#252869">
                    Ethics
                  </Box>{" "}
                  - We respect our customers and guarantee complete
                  confidentiality.
                </Box>
                <Box as="p" fontSize="21px" marginTop="20px">
                  <Box as="b" fontSize="22px" color="#252869">
                    Dedication
                  </Box>{" "}
                  - We are committed to helping businesses reduce operational
                  expenses by best possible solutions.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box> */}
    </Layout2>
  );
};

export default AboutPage;
